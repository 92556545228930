<template>
  <div>
    <v-card>
      <v-card-title
        class="dashboard-table d-flex flex-column"
      >
        {{ tableData.tableName }}
        <v-text-field
          class="my-2"
          v-model="search"
          append-icon="mdi-magnify"
          :label="`Buscar por ${tableData.headers[0].text}...`"
          style="width: 100%;"
          single-line
          hide-details
          solo
          clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :search="search"
        no-data-text="Esta tabla no contiene información."
        no-results-text="No se encontraron resultados."
      >
        <template v-slot:[`item.promedio_unidades_vendidas`]="{ item }">
          {{ item.promedio_unidades_vendidas | currency }}  
        </template>

        <template v-slot:[`item.semanas_inventario`]="{ item }">
          {{ item.semanas_inventario | currency }}  
        </template> 

        <template v-slot:[`item.ventas_totales`]="{ item }">
          {{ item.ventas_totales | currency | commaThousand }}  
        </template> 
        
        <template v-slot:[`item.total_compras`]="{ item }">
          {{ item.total_compras | currency | commaThousand }}  
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row justify-center>
            <v-col md="4">
              <v-icon
                icon
                title="Aprobar cancelación"
                @click="approve(item)"
              >
                mdi-check-circle
              </v-icon>
            </v-col>
            <v-col md="4">
              <v-icon
                icon
                title="Rechazar cancelación"
                @click="reject(item)"
              >
                mdi-close-circle
              </v-icon>
            </v-col>
          </v-row>
          
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { tFilters } from '@/mixins/filters.js'

export default {
  mixins: [tFilters],
  props: {
    tableData: Object
  },
  data() {
    return {
      search: '',
    }
  },
  methods: {
    approve(item) {
      item.actualizacion_estatus = 'Aprobado' 
      this.$emit('action1', item)
    },
    reject(item) {
      item.actualizacion_estatus = 'Cancelado'
      this.$emit('action1', item)
    }
  }
}
</script>

<style>
.dashboard-table {
  background-color: #2D4761;
  color: white;
}
.v-data-table-header tr th {
  background-color: #FF3700;
}
.v-data-table-header tr th span {
  color: white;
}
</style>