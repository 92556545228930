var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"dashboard-table d-flex flex-column"},[_vm._v(" "+_vm._s(_vm.tableData.tableName)+" "),_c('v-text-field',{staticClass:"my-2",staticStyle:{"width":"100%"},attrs:{"append-icon":"mdi-magnify","label":("Buscar por " + (_vm.tableData.headers[0].text) + "..."),"single-line":"","hide-details":"","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableData.headers,"items":_vm.tableData.items,"search":_vm.search,"no-data-text":"Esta tabla no contiene información.","no-results-text":"No se encontraron resultados."},scopedSlots:_vm._u([{key:"item.promedio_unidades_vendidas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.promedio_unidades_vendidas))+" ")]}},{key:"item.semanas_inventario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.semanas_inventario))+" ")]}},{key:"item.ventas_totales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("currency")(item.ventas_totales)))+" ")]}},{key:"item.total_compras",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("currency")(item.total_compras)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify-center":""}},[_c('v-col',{attrs:{"md":"4"}},[_c('v-icon',{attrs:{"icon":"","title":"Aprobar cancelación"},on:{"click":function($event){return _vm.approve(item)}}},[_vm._v(" mdi-check-circle ")])],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-icon',{attrs:{"icon":"","title":"Rechazar cancelación"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v(" mdi-close-circle ")])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }